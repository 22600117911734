import { createContext, useState, useEffect } from 'react';
export const GlobalContext = createContext();

const GlobalProvider = (props) => {

	let authToken = null;
	let isClient = false;
	let clientName = '';
	let transaction = '';

	let existingAuthToken = localStorage.getItem('authToken');
	if(existingAuthToken){
		authToken = existingAuthToken;
	}

	let existingIsClient = localStorage.getItem('isClient');
	if(existingIsClient && existingIsClient == "true"){
		isClient = true;
		clientName = localStorage.getItem('clientName');
		transaction = localStorage.getItem('transaction');
	}
	
	const [data, setData] = useState({
		authToken: authToken,
		isClient: isClient,
		clientName: clientName,
		transaction: transaction,
		activeTab: 'cards'
	});

	return <GlobalContext.Provider value={[data, setData]}>{props.children}</GlobalContext.Provider>
}

export {GlobalProvider};