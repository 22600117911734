import { useState, useEffect, useContext, useRef } from "react";
import { GlobalContext } from '../context/GlobalContext';
import { Link, useParams, Switch, Route, useRouteMatch } from 'react-router-dom';
import Cards from './Cards';
import EditClient from './EditClient';
import NotifyClient from './NotifyClient';
import Avatar from '../components/Avatar';
import { makeSvgAvatar } from '../utils';
import tawkTo from "tawkto-react";

import conf from "../conf.json";

const ClientPage = (props) => {

    const {id} = useParams();
    let { path, url } = useRouteMatch();

    const [ client, setClient ] = useState(null);
    const [ context, setContext ] = useContext(GlobalContext);

    useEffect(() => {
        if(!context.isClient){
            fetch(conf.api + '/clients/' + id, { headers: { 'admin-token': context.authToken }}).then(res => res.json()).then(res => {
                setClient(res);
                tawkTo(conf.tawkTo.propertyId, conf.tawkTo.key);
            });
        }
    }, []);

    return (
        <div>
            { client &&
                <div>
                    <div className="client-header">
                        <div className="name desk">{ client.name }</div>
                        <div className="mob"><Avatar name={client.name} color={client.color} /></div>
                        <div className="links">
                            <Link to={ url }><a className={ context.activeTab == 'cards' ? 'active' : ''}>Board</a></Link>
                            <Link to={ url + '/edit'}><a className={ context.activeTab == 'edit-client' ? 'active' : ''}>Edit<span className="desk"> Client</span></a></Link>
                            <Link to={ url + '/notify'}><a className={ context.activeTab == 'notify-client' ? 'active' : ''}>Send Notification</a></Link>
                        </div>
                    </div>
                    <Switch>
                        <Route exact path={path + '/edit'} render={(props) => (<EditClient onChanged={(updated) => setClient(updated)} {...props} />)} />
                        <Route exact path={path + '/notify'} render={(props) => (<NotifyClient client={client} {...props} />)} />
                        <Route exact path={path} render={(props) => (<Cards {...props} />)} />
                    </Switch>
                </div>
            }
            <div id="cometchat"></div>
        </div>
    )
}

export default ClientPage;