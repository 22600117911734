import conf from "../conf.json";
import { useState, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';

const CategoryName = (props) => {

    const [ category, setCategory ] = useState(props.category ? props.category : { name: '' });
    const [ loading, setLoading ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    const [ editing, setEditing ] = useState(false);
    const [ context, setContext ] = useContext(GlobalContext);

    const saveCategory = (e) => {
        e.preventDefault();
        setLoading(true);
        let url = conf.api + '/categories';
        if(props.category){
            url += '/' + category.id;
        }
        let theCategory = { name: category.name, client_id: props.client.id };
        fetch(url, {
            method: props.category ? 'PUT' : 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'admin-token': context.authToken
            },
            body: JSON.stringify(theCategory)
        }).then(res => res.json()).then(res => {
            setLoading(false);
            setEditing(false);
            if(props.onDone){
                props.onDone(res);
            }
        });
    }

    const deleteCategory = () => {
        if(window.confirm('Are you sure?')){
            setDeleting(true);
            fetch(conf.api + '/categories/' + props.category.id, {
                method: 'DELETE',
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'admin-token': context.authToken
                }
            }).then(res => res.json()).then(res => {
                setDeleting(false);
                setEditing(false);
                if(props.onDelete){
                    props.onDelete(category.id);
                }
            });
        }
    }

    return (
        <div>
            <div onClick={() => setEditing(true)} className={"title" + (props.category ? '' : ' empty')}>{ props.category ? category.name : '+ Add Category'}</div>
            { editing &&
                <form onSubmit={(e) => saveCategory(e)}>
                    <div className="dialog">
                        <div className="box small">
                            <div className="heading">
                                <h3 className="h3">{ props.category ? 'Edit' : 'Add' } Category</h3>
                                <span className="close" onClick={() => setEditing(false)}>&times;</span>
                            </div>
                            <div className="inner">
                                <input type="text" value={category.name} onChange={(e) => setCategory({...category, name: e.target.value })} className="field full"/>
                            </div>
                            <div className="actions">
                                <button className={"btn" + (loading ? ' loading' : '')}>
                                    <span className="spinner-wp"><span className="spinner"></span></span>
                                    <span>Save</span>
                                </button>
                                { props.category &&
                                    <span className="btn o-red" onClick={() => deleteCategory() }>
                                        <span className="spinner-wp"><span className="spinner dark"></span></span>
                                        <span>Delete</span>
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            }
        </div>
    )
}

export default CategoryName;