import conf from "../conf.json";
import { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import SelectField from "./SelectField";
import ManageAttachments from "./ManageAttachments";
import Switch from "./Switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faCheck, faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';


const CardDialog = (props) => {

    const [ card, setCard ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ duplicating, setDuplicating ] = useState(false);
    const [ errors, setErrors ] = useState(null);
    const [ contentEditorState, setContentEditorState ] = useState(null);
    const [ notesEditorState, setNotesEditorState ] = useState(null);
    const [ deleting, setDeleting ] = useState(false);
    const [ context, setContext ] = useContext(GlobalContext);

    useEffect(() => {

        if(props.card){
            setCard(props.card);

            // Init content editor
            if(!props.card || !props.card.content || props.card.content == ''){
                let editorState = EditorState.createEmpty();
                setContentEditorState(editorState);
            }else{
                let parsed = JSON.parse(props.card.content);
                if(parsed && parsed.blocks){
                    let editorState = EditorState.createWithContent(convertFromRaw(parsed));
                    setContentEditorState(editorState);
                }else{
                    const editorState = EditorState.createEmpty();
                    setContentEditorState(editorState);   
                }
            }

            // Init content editor
            if(!props.card || !props.card.notes || props.card.notes == ''){
                let editorState = EditorState.createEmpty();
                setNotesEditorState(editorState);   
            }else{
                let parsed = JSON.parse(props.card.notes);
                if(parsed && parsed.blocks){
                    let editorState = EditorState.createWithContent(convertFromRaw(parsed));
                    setNotesEditorState(editorState);
                }else{
                    const editorState = EditorState.createEmpty();
                    setNotesEditorState(editorState);   
                }
            }

        }else{
            
            let editorState = EditorState.createEmpty();
            setContentEditorState(editorState);  
            setNotesEditorState(editorState);

            setCard({
                category_id: props.categoryId ? props.categoryId : 0,
                name: '',
                content: '',
                notes: '',
                admin_responsible: true,
                active: true
            });
        }

    }, []);


    const saveCard = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors(null);
        let url = conf.api + '/cards';
        if(card.id){
            url = url + '/' + card.id;
        }

        let saveCard = {...card};
        let contentValue = contentEditorState.getCurrentContent();
        let notesValue = notesEditorState.getCurrentContent();
        saveCard.content = JSON.stringify(convertToRaw(contentValue));
        saveCard.notes = JSON.stringify(convertToRaw(notesValue));

        fetch(url, {
            method: card.id ? 'PUT' : 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'admin-token': context.authToken
            },
            body: JSON.stringify(saveCard)
        }).then(res => res.json()).then(res => {
            setLoading(false);
            if(res.errors){
                setErrors(res.errors);
            }else{
                if(props.onDone){
                    props.onDone(res);
                }
            }
        });
    }

    const duplicate = () => {
        setDuplicating(true);
        fetch(conf.api + '/cards/duplicate/' + card.id, {
            method: 'POST',
            headers: {
                'admin-token': context.authToken
            }
        }).then(res => res.json()).then(res => {
            setDuplicating(false);
            if(props.onDuplicated){
                props.onDuplicated(res);
            }
        });
    }

    const deleteCard = () => {
        if(window.confirm('Are you sure?')){
            setDeleting(true);
            fetch(conf.api + '/cards/' + card.id, {
                method: 'DELETE',
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'admin-token': context.authToken
                }
            }).then(res => res.json()).then(res => {
                setDeleting(false);
                if(props.onDelete){
                    props.onDelete(card.id);
                }
            });
        }
    }

    return (
        <form onSubmit={(e) => saveCard(e)}>
            { card &&
                <div className="dialog">
                    <div className="box large">
                        <div className="heading">
                            <h2 className="h2">{ card.id ? 'Edit' : 'Add' } Card</h2>
                            <span className="close" onClick={() => props.onClose()}>&times;</span>
                        </div>
                        <div className="inner">
                            <div className="grid grid-3-1 grid-break narrow-h">
                                <div>
                                    <div className={ errors && errors.name ? 'invalid' : ''}>
                                        <label className="caps">Title</label>
                                        <input type="text" className="field full mt-1" value={ card.name } onChange={(e) => setCard({...card, name: e.target.value })} />
                                        { errors && errors.name && <div className="error-msg">{ errors.name[0] }</div> }
                                    </div>
                                    <div className="mt-3">
                                        <label className="caps">Description</label>
                                        <div className="mt-1">
                                            <Editor
                                                editorState={ contentEditorState }
                                                toolbar={ conf.draftToolbar }
                                                wrapperClassName="field full"
                                                editorClassName="text"
                                                toolbarClassName="draft-toolbar"
                                                onEditorStateChange={ (editorState) => {
                                                    setContentEditorState(editorState)
                                                } }
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="caps">Admin Notes</label>
                                        <div className="mt-1">
                                            <Editor
                                                editorState={ notesEditorState }
                                                toolbar={ conf.draftToolbar }
                                                wrapperClassName="field full"
                                                editorClassName="text"
                                                toolbarClassName="draft-toolbar"
                                                onEditorStateChange={ (editorState) => {
                                                    setNotesEditorState(editorState)
                                                } }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label className="caps">Responsible</label>
                                        <div className="mt-1">
                                            <SelectField value={ card.admin_responsible } options={[{ label: 'Augie', value: 1 }, { label: props.client.name, value: 0 }]} onChange={(val) => setCard({...card, admin_responsible: val }) } />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="caps">Attachments</label>
                                        { props.card ? (
                                                <ManageAttachments onChange={(updated) => setCard({...card, attachments: updated })} card={card} />
                                            ) : (
                                                <ManageAttachments onChange={(updated) => setCard({...card, attachments: updated })} />
                                            )
                                        }
                                    </div>
                                    <div className="switch-wp mt-3">
                                        <Switch on={!card.active} onChange={(on) => setCard({...card, active: !on})} />
                                        <label>Admin only</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="actions">
                            <button className={"btn" + (loading ? ' loading' : '')}>
                                <div className="spinner-wp"><div className="spinner"></div></div>
                                <span>Save</span>
                            </button>
                            { card.id &&
                                <span className={"btn outlined" + (duplicating ? ' loading' : '')} onClick={() => duplicate()}>
                                    <div className="spinner-wp"><div className="spinner dark"></div></div>
                                    <span className="desk">Duplicate</span>
                                    <span className="mob"><FontAwesomeIcon icon={faCopy} /></span>
                                </span>
                            }
                            { card.id &&
                                <span className={"right btn o-red" + (deleting ? ' loading' : '')} onClick={() => deleteCard()}>
                                    <div className="spinner-wp"><div className="spinner dark"></div></div>
                                    <span className="desk">Delete</span>
                                    <span className="mob"><FontAwesomeIcon icon={faTrash} /></span>
                                </span>
                            }
                            
                        </div>
                    </div>
                </div>
            }
        </form>
    )
}

export default CardDialog;