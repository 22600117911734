import conf from "../conf.json";
import { useEffect, useState, useContext } from "react";
import { GlobalContext } from '../context/GlobalContext';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const EditClient = (props) => {

    const [ context, setContext ] = useContext(GlobalContext);
    const [ client, setClient ] = useState(null);
    const [ sent, setSent ] = useState(false);
    
    let emptyState = EditorState.createEmpty();
    const [ editorState, setEditorState ] = useState( context.notification && context.notification.editorState ? context.notification.editorState : emptyState );
    const [ subject, setSubject ] = useState(context.notification && context.notification.subject ? context.notification.subject : '');
    const [ loading, setLoading ] = useState(false);
    

    useEffect(() => {
        setContext({...context, notification: {
            subject: subject, 
            editorState: editorState
        }});
    }, [ subject, editorState ]);

    useEffect(() => {
        setContext({...context, activeTab: 'notify-client'});
        fetch(conf.api + '/clients/' + props.client.id, {
            method: 'GET',
            headers: {
                'admin-token': context.authToken
            }
        }).then(res => {
            if(res.ok){
                return res.json();
            }else{
                setContext({...context, authToken: null});
            }
        }).then(res => {
            if(res){
                setClient(res);
            }
        });
    }, []);

    const id = props.match.params.id;

    const sendEmail = (e) => {
        e.preventDefault(e);
        setLoading(true);
        fetch(conf.api + '/clients/notify', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'admin-token': context.authToken
            },
            body: JSON.stringify({
                client_id: props.client.id,
                subject: subject,
                message: stateToHTML(editorState.getCurrentContent())
            })
        }).then(res => res.json()).then(res => {
            setLoading(false);
            if(res.client){
                setSent(true);
                setClient(res.client);
                setSubject('');
                setEditorState(emptyState);
            }
        });
    }

    return (
        <form onSubmit={(e) => sendEmail(e)}>
            { client &&
                <div className="width pt-7">
                    <div className="box large">
                        <div className="heading">
                            <h2 className="h2">Send Notification</h2>
                        </div>
                        { !sent ? (
                            <div>
                                <div className="inner">
                                    <div>
                                        <label className="caps">Subject</label>
                                        <input type="text" className="field full mt-1" value={subject} onChange={(e) => setSubject(e.target.value)}/>
                                    </div>
                                    <div className="mt-3">
                                        <label className="caps">Version</label>
                                        <div className="mt-1">
                                            <input type="text" className="field small disabled" disabled value={ client.notifications + 1 }/>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="caps">Custom Message</label>
                                        <div className="mt-1">
                                            <Editor
                                                editorState={ editorState }
                                                toolbar={ conf.draftToolbar }
                                                wrapperClassName="field full"
                                                editorClassName="text"
                                                toolbarClassName="draft-toolbar"
                                                onEditorStateChange={ (editorState) => {
                                                    setEditorState(editorState)
                                                } }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="actions">
                                    <button className={"btn" + (loading ? ' loading' : '')}>
                                        <span className="spinner-wp"><span className="spinner"></span></span>
                                        <span>Send</span>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="inner">
                                    Your notification was sent successfully
                                </div>
                                <div className="actions">
                                    <span className="btn" onClick={() => setSent(false)}><span>Send another notification</span></span>
                                </div>
                            </div>
                        ) }
                    </div>
                </div>
            }
        </form>
    );
}

export default EditClient;