import Avatar from './Avatar';
import { Link } from 'react-router-dom';
import DraftText from "./DraftText";
import { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import conf from "../conf.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Client = (props) => {

    const [ openTodoField, setOpenTodoField ] = useState('');
    const [ currentTodoState, setCurrentTodoState ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ client, setClient ] = useState(props.client);
    const [ context, setContext ] = useContext(GlobalContext);

    const labels = {
        todo_immediate: "Immediate",
        todo_long_term: "Long Term",
        notes: "Notes"
    }

    const openEditor = (e, field) => {
        const targetLink = e.target.closest('a');
        if(!targetLink){
            setOpenTodoField(field);   
        }
    }

    useEffect(() => {
        if(openTodoField != ''){
            if(!client[openTodoField] || client[openTodoField] == ''){
                const editorState = EditorState.createEmpty();
                setCurrentTodoState(editorState);   
            }else{
                const parsed = JSON.parse(client[openTodoField]);
                if(parsed && parsed.blocks){
                    const editorState = EditorState.createWithContent(convertFromRaw(parsed));
                    setCurrentTodoState(editorState);
                }else{
                    const editorState = EditorState.createEmpty();
                    setCurrentTodoState(editorState);   
                }
            }
        }else{
            setCurrentTodoState('');
        }
    }, [ openTodoField ]);

    const submitForm = (e) => {
        e.preventDefault();
        setLoading(true);
        let currentContent = currentTodoState.getCurrentContent();
        let raw = convertToRaw(currentContent);
        if(context.authToken){
            fetch(conf.api + '/clients/' + client.id, {
                method: 'PUT',
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'admin-token': context.authToken
                },
                body: JSON.stringify({
                    [openTodoField]: JSON.stringify(raw)
                })
            }).then(res => {
                if(res.ok){
                    return res.json();
                }else if(res.status == 403){
                    setContext({...context, authToken: null});
                }
            }).then(res => {
                setLoading(false);
                setClient(res);
                setOpenTodoField('');
            });   
        }
    }

    return (
        <div className={"client-wp" + (client.active ? '' : ' inactive')}>
            <div className="client grid grid-4 grid-break no-gap">
                <div className="info">
                <Link to={'/client/' + client.id}><Avatar name={client.name} color={client.color} /></Link>
                    <div className="details">
                        <Link to={'/client/' + client.id}><h4 className="h4">{client.name}</h4></Link>
                        <span className="meta text">{ client.transaction } &ndash; { client.contact }</span>
                    </div>
                </div>
                <div className="todo" onClick={(e) => openEditor(e, 'todo_immediate')}>
                    <div className="caps mob mb-1">Immediate</div>
                    <DraftText content={ client.todo_immediate } />
                </div>
                <div className="todo" onClick={(e) => openEditor(e, 'todo_long_term')}>
                    <div className="caps mob mb-1">Long Term</div>
                    <DraftText content={ client.todo_long_term } />
                </div>
                <div className="todo" onClick={(e) => openEditor(e, 'notes')}>
                    <div className="caps mob mb-1">Notes</div>
                    <DraftText content={ client.notes } />
                </div>
                <div className="mob controls">
                    <span>&nbsp;</span>
                    <span className="icon right" onClick={(e) => { e.preventDefault(); props.onMoveUp() }}><FontAwesomeIcon icon={faChevronUp} /></span>
                    <span className="icon" onClick={(e) => { e.preventDefault(); props.onMoveDown()}}><FontAwesomeIcon icon={faChevronDown} /></span>
                </div>
            </div>
            { currentTodoState != '' &&
                <form onSubmit={(e) => submitForm(e)}>
                    <div className="dialog">
                        <div className="box">
                            <div className="heading">
                                <h2 className="h2">Todo: { labels[openTodoField] }</h2>
                                <span className="close" onClick={() => setOpenTodoField('')}>&times;</span>
                            </div>
                            <div className="inner">
                                <Editor
                                    editorState={ currentTodoState }
                                    toolbar={ conf.draftToolbar }
                                    wrapperClassName="field full"
                                    editorClassName="text"
                                    toolbarClassName="draft-toolbar"
                                    onEditorStateChange={ (editorState) => {
                                        setCurrentTodoState(editorState)
                                    } }
                                />
                            </div>
                            <div className="actions">
                                <button className={"btn" + (loading ? ' loading' : '')}>
                                    <div className="spinner-wp"><div className="spinner"></div></div>
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </div>
    )
}

export default Client;