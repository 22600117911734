import conf from "../conf.json";
import { useEffect, useState, useContext } from "react";
import Client from '../components/Client';
import ClientForm from '../components/ClientForm';
import { ReactSortable } from "react-sortablejs";
import { GlobalContext } from '../context/GlobalContext';

const Clients = () => {

    const [ clients, setClients ] = useState([]);
    const [ adding, setAdding ] = useState(false);
    const [ logged, setLogged ] = useState(true);
    const [ context, setContext ] = useContext(GlobalContext);

    useEffect(() => {
        if(context.authToken){
            fetch(conf.api + '/clients', {
                headers: { 
                    'admin-token': context.authToken,
                },
            }).then(res => {
                if(res.ok){
                    return res.json();
                }else if(res.status == 403){
                    setContext({...context, authToken: null});
                }
            }).then(res => {
                setClients(res);
            });
        }
    }, [ context ]);

    const updateOrder = (updatedClients = null) => {
        if(!updatedClients){
            updatedClients = [...clients];
        }
        fetch(conf.api + '/clients/update-order', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'admin-token': context.authToken
            },
            body: JSON.stringify(updatedClients.map(client => client.id))
        }).then(res => res.json()).then(res => {
            console.log('updated ordr', res);
        });
    }

    const moveUp = (id) => {
        let oldIndex = 0;
        for(let i = 0; i < clients.length; i++){
            if(clients[i].id == id){
                oldIndex = i;
            }
        }
        if(oldIndex > 0){
            let updatedClients = [];
            for(let i = 0; i < clients.length; i++){
                if(i == oldIndex - 1){
                    updatedClients.push(clients[oldIndex]);
                }else if(i == oldIndex){
                    updatedClients.push(clients[oldIndex - 1]);
                }else{
                    updatedClients.push(clients[i]);
                }
            }
            setClients(updatedClients);
            updateOrder(updatedClients);
        }
    }

    const moveDown = (id) => {
        let oldIndex = 0;
        for(let i = 0; i < clients.length; i++){
            if(clients[i].id == id){
                oldIndex = i;
            }
        }
        if(oldIndex < clients.length - 1){
            let updatedClients = [];
            for(let i = 0; i < clients.length; i++){
                if(i == oldIndex){
                    updatedClients.push(clients[oldIndex + 1]);
                }else if(i == oldIndex + 1){
                    updatedClients.push(clients[oldIndex]);
                }else{
                    updatedClients.push(clients[i]);
                }
            }
            setClients(updatedClients);
            updateOrder(updatedClients);
        }
    }

    return (
        <div className="width mt-5">

            <div className="top">
                <h1 className="h1">Clients</h1>
                <div className="right">
                    <span className="btn outlined" onClick={() => setAdding(true)}><span>+ Client</span></span>
                </div>
            </div>

            <div className="grid grid-4 no-gap mt-3 desk">
                <div className="caps">Client</div>
                <div className="caps">Immediate</div>
                <div className="caps">Long Term</div>
                <div className="caps">Notes</div>
            </div>
            { clients && clients.length > 0 &&
                <div className="clients mt-1">
                    <ReactSortable list={clients} setList={(updated) => setClients(updated)} onEnd={(e) => updateOrder()}>
                        { clients.map((client) => (
                            <Client 
                                key={client.id} 
                                client={client} 
                                onMoveUp={() => moveUp(client.id)}
                                onMoveDown={() => moveDown(client.id)}
                            />
                        ))}
                    </ReactSortable>
                </div>
            }

            { adding && 
                <div className="dialog">
                    <div className="box">
                        <div className="heading">
                            <h2 className="h2">Add Client</h2>
                            <span className="close" onClick={() => setAdding(false)}>&times;</span>
                        </div>
                        <ClientForm onDone={(newClient) => {
                            setClients([...clients, newClient ]);
                            setAdding(false);
                        }} />
                    </div>
                </div>
            }
        </div>
    )
}

export default Clients;