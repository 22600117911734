import conf from "../conf.json";
import { useState, useContext } from "react";
import { GlobalContext } from '../context/GlobalContext';


const Login = () => {

    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ context, setContext ] = useContext(GlobalContext);

    const doLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        fetch(conf.api + '/login', { 
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                password: password
            })
        }).then(res => res.json()).then(res => {
            setLoading(false);
            if(res.error){
                setError(res.error);
            }else{
                localStorage.setItem('authToken', res.token);
                localStorage.setItem('isClient', res.client);
                if(res.client){
                    localStorage.setItem('clientName', res.name);
                    localStorage.setItem('transaction', res.transaction);
                }
                setContext({
                    ...context, 
                    authToken: res.token, 
                    isClient: res.client, 
                    clientName: res.client ? res.name : '', 
                    transaction: res.client ? res.transaction  : ''
                });
            }
        });
    }

    return (
        <div className="width pt-5">
            <form onSubmit={(e) => doLogin(e)}>
                <div className="box">
                    <div className="heading">
                        <h3 className="h3">Login</h3>
                    </div>
                    <div className="inner">
                        <div className={ error ? 'invalid' : ''}>
                            <input type="password" className="field full" value={password} onChange={(e) => setPassword(e.target.value)} />
                            { error && <div className="error-msg mt-1">{error}</div> }
                        </div>
                    </div>
                    <div className="actions">
                        <button className={"btn" + (loading ? ' loading' : '')}>
                            <div className="spinner-wp"><div className="spinner"></div></div>
                            <span>Login</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Login;