import conf from "../conf.json";
import { useEffect, useState, useContext } from "react";
import Card from '../components/Card';
import Percentages from '../components/Percentages';
import { GlobalContext } from '../context/GlobalContext';
import Talk from "talkjs";
import { makeSvgAvatar } from '../utils';

const ClientBoard = (props) => {

    const [ categories, setCategories ] = useState(null);
    const [ client, setClient ] = useState(null);
    const [ context, setContext ] = useContext(GlobalContext);

    useEffect(() => {
        fetch(conf.api + '/board', {
            headers: {
                'client-token': context.authToken
            }
        }).then(res => res.json()).then(res => {
            setCategories(res.categories);
            setClient(res.client);
            Talk.ready.then(() => {
                var me = new Talk.User({
                    id: res.client.id,
                    name: res.client.contact,
                    email: res.client.email,
                    photoUrl: makeSvgAvatar(res.client.color, res.client.contact.charAt(0)),
                    role: "default"
                });
                window.talkSession = new Talk.Session({
                    appId: conf.talkJsId,
                    me: me
                });
                var other = new Talk.User(conf.talkJsUser);
                var conversation = window.talkSession.getOrCreateConversation(Talk.oneOnOneId(me, other));
                conversation.setParticipant(me);
                conversation.setParticipant(other);
                const popup = window.talkSession.createPopup(conversation, { keepOpen: false });
                popup.mount({ show: false });
            });
        });
    }, []);

    return (
        <div>
            { (categories && client) &&
                <div>
                    <Percentages client={client} isClient={true} />
                    <div className="categories-wp">
                        <div className="width">
                            <div className="grid categories-grid mt-4" style={{ gridTemplateColumns: 'repeat(' + (categories.length) + ', 1fr)' }}>
                                { categories.map(category => (
                                    <div className="category" key={category.id}>
                                        <div className="title">{category.name}</div>
                                        { category.cards.map(card => (
                                            <Card card={card} client={client} key={card.id} isClient={true} />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ClientBoard;