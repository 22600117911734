import conf from "../conf.json";
import Avatar from './Avatar';
import DraftText from "./DraftText";
import CardDialog from "./CardDialog";
import ClientCardDialog from "./ClientCardDialog";
import { useState, useEffect } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AttachmentItem from './AttachmentItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';


const Card = (props) => {

    const [ open, setOpen ] = useState(false);
    const [ card, setCard ] = useState(props.card);
    const [ moveOn, setMoveOn ] = useState(false);

    return (
        <div>
            <div className={"card" + (card.active ? '' : ' inactive')}>
                <div className="inner" onClick={() => setOpen(true)}>
                    <div className="img">
                        { card.admin_responsible ? (
                            <img src="/img/BetterSeriesALogo.png" alt="BetterSeriesA"/>
                        ) : (
                            <Avatar name={props.client.contact} color={props.client.color} />
                        ) }
                    </div>
                    <div className="details">
                        <h4 className="h4">{card.name}</h4>
                        <div className="text">
                            <DraftText content={ card.content } truncated={100} />
                        </div>
                        { card.attachments && card.attachments.length > 0 &&
                            <div className="attachments">
                                { card.attachments.map((item, key) => (
                                    <AttachmentItem key={key} attachment={item} />
                                ))}
                            </div>
                        }
                    </div>
                </div>
                { !props.isClient &&
                    <div className="mob controls">
                        <span className="move" onClick={(e) => setMoveOn(true)}>Move &hellip;</span>
                        <span className="icon right" onClick={(e) => { e.preventDefault(); props.onMoveUp() }}><FontAwesomeIcon icon={faChevronUp} /></span>
                        <span className="icon" onClick={(e) => { e.preventDefault(); props.onMoveDown()}}><FontAwesomeIcon icon={faChevronDown} /></span>
                    </div>
                }
            </div>
            { open &&
                <div>
                    { props.isClient ? (
                        <ClientCardDialog card={card} client={props.client} onClose={() => setOpen(false)} />
                    ) : (
                        <CardDialog 
                            card={card} 
                            client={props.client} 
                            onClose={() => setOpen(false)} 
                            onDone={(updatedCard) => {
                                setCard(updatedCard);
                                if(props.onChanged){
                                    props.onChanged(updatedCard);
                                }
                                setOpen(false);
                            }}
                            onDuplicated={(newCard) => {
                                if(props.onDuplicated){
                                    props.onDuplicated(newCard);
                                }
                                setOpen(false);
                            }} 
                            onDelete={(cardId) => {
                                if(props.onDelete){
                                    props.onDelete(cardId);
                                }
                            }}
                        />
                    ) }
                </div>
            }
            { (moveOn && props.categories) &&
                <div className="dialog">
                    <div className="box">
                        <div className="heading">
                            <h3 className="h3">Move Card</h3>
                            <span className="close" onClick={() => setMoveOn(false)}>&times;</span>
                        </div>
                        <div className="inner">
                            <div className="caps">Select Category</div>
                            <div className="select-category">
                                { props.categories.map(item => (
                                    <div className={"option" + (item.id == card.category_id ? ' active' : '')} key={item.id}  onClick={() => {
                                        if(item.id != card.category_id){
                                            if(props.onMove){
                                                console.log('on move here');
                                                props.onMove(item.id);
                                            }else{
                                                console.log('not on move');
                                            }
                                        }else{
                                            console.log('not hre');
                                        }
                                    }}>
                                        <div className="icon">
                                            { card.category_id == item.id && <FontAwesomeIcon icon={faCheck} />}
                                        </div>
                                        { item.label }
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="actions">
                            <span className="btn outlined" onClick={() => setMoveOn(false)}><span>Cancel</span></span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Card;