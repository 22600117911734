import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import { useEffect, useState } from 'react';

const DraftText = ({content, truncated}) => {

    const [ contentHTML, setContentHTML ] = useState('');
    
    useEffect(() => {
        if(content){
            const parsed = JSON.parse(content);
            if(parsed.blocks){
                const contentState = convertFromRaw(parsed);
                setContentHTML(stateToHTML(contentState));
                //setEditorState(EditorState.createWithContent(contentState));
            }
        }
    }, [ content ]);

    const truncateStr = (str, num) => {
        if (str.length <= num) {
            return str
        }
        return str.slice(0, num) + '...'
    }


    return (
        <div>
            { truncated ? (
                <div>{ truncateStr(contentHTML.replace(/(<([^>]+)>)/gi, ""), truncated) }</div>
            ) : (
                <div className="text" dangerouslySetInnerHTML={{ __html: contentHTML }}></div>
            )}
        </div>
    );
}

export default DraftText;