import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Clients from './pages/Clients';
import ClientBoard from './pages/ClientBoard';
import Cards from './pages/Cards';
import EditClient from './pages/EditClient';
import ClientPage from './pages/ClientPage';
import { GlobalContext } from './context/GlobalContext';
import Login from './components/Login';
import ChangePassword from './pages/ChangePassword';

const Pages = () => {

    const [ context, setContext ] = useContext(GlobalContext);

	return (
        <div>
            { context.authToken ? (
                <div>
                    { context.isClient ? (
                        <ClientBoard />
                    ) : (
                        <Switch>
                            <Route path="/client/:id">
                                <ClientPage />
                            </Route>
                            <Route path='/change-password' render={(props) => (<ChangePassword {...props} />)} />
                            <Route path='/' render={(props) => (<Clients {...props} />)} />
                        </Switch>
                    ) }
                </div>
                ) : (
                    <Login />
                )
            }
        </div>
    )
}

export default Pages;