import conf from "../conf.json";
import { useEffect, useState, useContext } from "react";
import { GlobalContext } from '../context/GlobalContext';

const ChangePassword = (props) => {

    const [ context, setContext ] = useContext(GlobalContext);
    const [ done, setDone ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ oldPassword, setOldPassword ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ error, setError ] = useState(null);
    
    const savePassword = (e) => {
        e.preventDefault(e);
        setLoading(true);
        setError(null);
        fetch(conf.api + '/change-password', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'admin-token': context.authToken
            },
            body: JSON.stringify({
                oldPassword: oldPassword,
                password: newPassword
            })
        }).then(res => res.json()).then(res => {
            if(res.error){
                setError(res.error);
            }else{
                setContext({...context, authToken: res.token});
                setDone(true);
            }
            setLoading(false);
        });
    }

    return (
        <form onSubmit={(e) => savePassword(e)}>

            <div className="width pt-7">
                <div className="box small">
                    <div className="heading">
                        <h2 className="h2">Change Admin Password</h2>
                    </div>
                    { !done ? (
                        <div>
                            <div className="inner">
                                <div className={ error ? 'invalid' : '' }>
                                    <label className="caps">Current Password</label>
                                    <input type="password" className="field full mt-1" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}/>
                                    { error && <div className="mt-1 error-msg">{ error }</div> }
                                </div>
                                <div className="mt-3">
                                    <label className="caps">New Password</label>
                                    <input type="password" className="field full mt-1" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                                </div>
                                <div className={"mt-3" + ( newPassword !== confirmPassword ? ' invalid' : '' )}>
                                    <label className="caps">Confirm New Password</label>
                                    <input type="password" className="field full mt-1" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                                    { newPassword !== confirmPassword && <div className="mt-1 error-msg">Passwords don't match</div> }
                                </div>
                            </div>
                            <div className="actions">
                                <button className={"btn" + (loading ? ' loading' : '')}>
                                    <span className="spinner-wp"><span className="spinner"></span></span>
                                    <span>Change Password</span>
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="inner">
                                Your password was updated successfully.
                            </div>
                            <div className="actions">
                                <span className="btn" onClick={() => {
                                    setOldPassword('');
                                    setNewPassword('');
                                    setConfirmPassword('');
                                    setDone(false)
                                }}><span>Change Again</span></span>
                            </div>
                        </div>
                    ) }
                </div>
            </div>

        </form>
    );
}

export default ChangePassword;