import conf from "../conf.json";
import { useState, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';

const UploadButton = ({onDone}) => {

  const [ uploading, setUploading ] = useState(false);
  const [ errors, setErrors ] = useState(null);
  const [ context, setContext ] = useContext(GlobalContext);

  const upload = (event) => {
    if(event.nativeEvent.target.files){
        let file = event.nativeEvent.target.files[0];
        let formData = new FormData();
        formData.append('file', file, file.name);
        setUploading(true);
        setErrors(null);
        fetch(conf.api + '/attachments/upload', { 
            method: 'POST',
            headers: {
                'admin-token': context.authToken
            },
            body: formData
        }).then(res => res.json()).then(res => {
            setUploading(false);
            if(res.error){
                if(res.error.file){
                    setErrors(res.error.file);
                }else{
                    setErrors(['Upload failed. Please try again.']);
                }
            }else{
                onDone(res);
            }
        }).catch(e => {
            setUploading(false);
            setErrors(['Upload failed. Please try again.']);
        });
    }
  }

  return (
        <div>
        <span className={"btn small outlined btn-upload" + (uploading ? ' loading' : '')}>
                <div className="spinner-wp"><div className="spinner dark"></div></div>
                <span>Upload</span>
                <input onChange={(e) => upload(e)} type="file" />
            </span>
            { errors && 
                <div className="dialog">
                    <div className="box small">
                        <div className="heading">
                            <h3 className="h3">Errors</h3>
                        </div>
                        <div className="inner">
                            { errors.map((err, key) => (<div className="error-msg" key={key}>{err}</div>))}
                        </div>
                        <div className="actions">
                            <span className="btn" onClick={() => setErrors(null)}><span>Okay</span></span>
                        </div>
                    </div>
                </div>
            }
        </div>
  );
}


export default UploadButton;
