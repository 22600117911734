import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

const SelectField = (props) => {

  const onChange = (event) => {
    props.onChange(event.target.value);
  }
  
  return (
    <div className="field select wp">
      <select disabled={props.disabled} onChange={onChange} defaultValue={props.value}>
        { props.emptyLabel && (
          <option value="">{props.emptyLabel}</option>
        )}
        { props.options.map((item, index) => (
          <option disabled={item.disabled} key={index} value={item.value} >{item.label}</option>
        )) }
      </select>
      <div className="caret">
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
    </div>
  );
}


export default SelectField;
