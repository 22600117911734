import conf from "../conf.json";
import { useEffect, useState, useContext } from "react";
import { ReactSortable } from "react-sortablejs";
import CardDialog from '../components/CardDialog';
import Card from '../components/Card';
import CategoryName from '../components/CategoryName';
import Updating from '../components/Updating';
import Percentages from '../components/Percentages';
import { GlobalContext } from '../context/GlobalContext';

const Cards = (props) => {

    const id = props.match.params.id;
    const [ categories, setCategories ] = useState(null);
    const [ client, setClient ] = useState(null);
    const [ addingCard, setAddingCard ] = useState(null); // param = category id
    const [ addingCategory, setAddingCategory ] = useState(false);
    const [ updating, setUpdating ] = useState(false);
    const [ context, setContext ] = useContext(GlobalContext);

    useEffect(() => {
        fetch(conf.api + '/categories/by-client/' + id, {
            headers: {
                'admin-token': context.authToken
            }
        }).then(res => res.json()).then(res => {
            setCategories(res.categories);
            setClient(res.client);
        });
        console.log('here', context);
        setContext({...context, activeTab: 'cards' });
    }, []);

    const cardAdded = (newCard) => {
        let updatedCategories = [...categories];
        for(let i = 0; i < updatedCategories.length; i++){
            if(updatedCategories[i].id == newCard.category_id){
                updatedCategories[i].cards.push(newCard);
            }
        }
        setCategories(updatedCategories);
    }

    const updateCardOrder = (categoryId, updated) => {
        let updatedCategories = [...categories];
        for(let i = 0; i < updatedCategories.length; i++){
            if(categories[i].id == categoryId){
                categories[i].cards = updated;
            }
        }
        setCategories(updatedCategories);
    }

    const saveUpdatedOrders = (updatedCategories = null) => {
        if(!updatedCategories){
            updatedCategories = [...categories];
        }
        setUpdating(true);
        fetch(conf.api + '/cards/update-order', {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'admin-token': context.authToken
            },
            body: JSON.stringify(updatedCategories.map(category => {
                return {
                    id: category.id,
                    cards: category.cards.map(card => card.id)
                }
            }))
        }).then(res => res.json()).then(res => {
            setUpdating(false);
        });
    }

    const cardChanged = (updatedCard) => {
        let updatedCategories = [...categories];
        for(let i = 0; i < updatedCategories.length; i++){
            for(let j = 0; j < updatedCategories[i].cards.length; j++){
                if(updatedCategories[i].cards[j].id == updatedCard.id){
                    updatedCategories[i].cards[j] = updatedCard;
                }
            }
        }
        setCategories(updatedCategories);
    }

    const removeCard = (cardId) => {
        let updatedCategories = categories.map(category => {
            return {...category, cards : category.cards.filter(card => card.id != cardId )}
        });
        setCategories(updatedCategories);
        saveUpdatedOrders(updatedCategories);
    }

    const moveUp = (id) => {
        let updatedCategories = categories.map(category => {
            if(category.cards.filter(card => card.id == id).length > 0){
                let oldIndex = 0;
                for(let i = 0; i < category.cards.length; i++){
                    if(category.cards[i].id == id){
                        oldIndex = i;
                    }
                }
                if(oldIndex > 0){
                    let newCards = [];
                    for(let i = 0; i < category.cards.length; i++){
                        if(i == oldIndex - 1){
                            newCards.push(category.cards[oldIndex]);
                        }else if(i == oldIndex){
                            newCards.push(category.cards[oldIndex - 1]);
                        }else{
                            newCards.push(category.cards[i]);
                        }
                    }
                    return { ...category, cards: newCards }
                }else{
                    return category;
                }
            }else{
                return category;
            }
        });
        setCategories(updatedCategories);
        saveUpdatedOrders(updatedCategories);
    }

    const moveDown = (id) => {
        let updatedCategories = categories.map(category => {
            if(category.cards.filter(card => card.id == id).length > 0){
                let oldIndex = 0;
                for(let i = 0; i < category.cards.length; i++){
                    if(category.cards[i].id == id){
                        oldIndex = i;
                    }
                }
                if(oldIndex < category.cards.length - 1){
                    let newCards = [];
                    for(let i = 0; i < category.cards.length; i++){
                        if(i == oldIndex){
                            newCards.push(category.cards[oldIndex + 1]);
                        }else if(i == oldIndex + 1){
                            newCards.push(category.cards[oldIndex]);
                        }else{
                            newCards.push(category.cards[i]);
                        }
                    }
                    return { ...category, cards: newCards }
                }else{
                    return category;
                }
            }else{
                return category;
            }
        });
        setCategories(updatedCategories);
        saveUpdatedOrders(updatedCategories);
    }

    const moveCard = (card, categoryId) => {
        let updatedCategories = categories.map(category => {
            if(category.id == categoryId){
                return {
                    ...category,
                    cards: [...category.cards, card]
                }
            }else{
                return {
                    ...category,
                    cards: category.cards.filter(item => item.id != card.id)
                }
            }
        });
        setCategories(updatedCategories);
        saveUpdatedOrders(updatedCategories);
    }

    return (
        <div>
            { updating && <Updating /> }
            { (categories && client) &&
                <div>
                    <Percentages client={client} isClient={false} />
                    <div className="categories-wp">
                        <div className="width">
                            <div className="grid categories-grid mt-4" style={{ gridTemplateColumns: 'repeat(' + (categories.length + 1) + ', 320px)' }}>
                                { categories.map(category => (
                                    <div className="category" key={category.id}>
                                        <CategoryName client={client} category={category} onDelete={(categoryId) => setCategories(categories.filter(item => item.id != categoryId))} />
                                        <ReactSortable list={ category.cards } group="shared" setList={ (updated) => updateCardOrder(category.id, updated) } onEnd={(e) => saveUpdatedOrders()}>
                                            { category.cards.map(card => (
                                                <Card 
                                                    card={card} 
                                                    client={client} 
                                                    key={card.id} 
                                                    onChanged={(updated) => cardChanged(updated)} 
                                                    onDuplicated={(newCard) => cardAdded(newCard)} 
                                                    onDelete={(cardId) => removeCard(cardId)}
                                                    onMoveUp={() => moveUp(card.id)}
                                                    onMoveDown={() => moveDown(card.id)}
                                                    onMove={(categoryId) => moveCard(card, categoryId)}
                                                    categories={ categories.map(item => {
                                                        return {
                                                            label: item.name,
                                                            id: item.id
                                                        }
                                                    })}
                                                />
                                            ))}
                                        </ReactSortable>
                                        <div className="btn gray add-item" onClick={() => setAddingCard(category.id) }>+ Add Card</div>
                                    </div>
                                ))}
                                <div className="category">
                                    <CategoryName client={client} onDone={(newCategory) => setCategories([...categories, {...newCategory, cards: []} ])} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            { addingCard && 
                <CardDialog 
                    categoryId={ addingCard } 
                    client={ client }
                    onDone={ (newCard) => {
                        cardAdded(newCard);
                        setAddingCard(null);
                    }} 
                    onClose={ () => setAddingCard(null) }
                />
            }
            <style jsx>{`
                .title{ font-size: 16px; font-family: 'clarika-dm'; margin-bottom: 12px; }
            `}</style>
        </div>
    );
}

export default Cards;