import DraftText from './DraftText';
import Avatar from './Avatar';
import AttachmentItem from './AttachmentItem';

const ClientCardDialog = ({ card, client, onClose }) => {

    return (
        <div>
            { card &&
                <div className="dialog">
                    <div className="box medium">
                        <div className="heading simple">
                            <h2 className="h2">{ card.name }</h2>
                            <span className="close" onClick={() => onClose()}>&times;</span>
                        </div>
                        <div className="inner">
                            <div className="grid grid-3-1 narrow-h">
                                <div>
                                    <DraftText content={ card.content }/>
                                </div>
                                <div>
                                    { card.attachments && card.attachments.length > 0 &&
                                        <div className="mb-4">
                                            <label className="caps">Attachments</label>
                                            <div className="attachments">
                                                { card.attachments.map((item, key) => (
                                                    <AttachmentItem key={key} attachment={item} />
                                                ))}
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <label className="caps">Responsible</label>
                                        <div className="mt-1 responsible">
                                            
                                            { card.admin_responsible ? (
                                                <div className="responsible">
                                                    <img src="/img/BetterSeriesALogo.png" alt="BetterSeriesA"/>
                                                    <span className="name">Augie</span>
                                                </div>
                                            ) : (
                                                <div className="responsible">
                                                    <Avatar name={client.name} color={client.color} />
                                                    <span className="name">{client.contact}</span>
                                                </div>
                                            ) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ClientCardDialog;