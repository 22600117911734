import conf from "../conf.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faFile } from '@fortawesome/free-solid-svg-icons';

const AttachmentItem = ({ attachment }) => {
    return (
        <div>
            { attachment.type == 'link' &&
                <a className="attachment-link" onClick={(e) => e.stopPropagation() } href={attachment.path} target="_blank">
                    <span className="icon"><FontAwesomeIcon icon={faLink} /></span>
                    <span>{attachment.name}</span>
                </a>
            }
            { attachment.type == 'file' &&
                <a className="attachment-link" href={conf.docsUrl + '/' + attachment.path.replace('public/', '')} onClick={(e) => e.stopPropagation() } target="_blank">
                    <span className="icon"><FontAwesomeIcon icon={faFile} /></span>
                    <span>{attachment.name}</span>
                </a>
            }
        </div>
    )
}

export default AttachmentItem;