const Switch = (props) => {

  const doSwitch = () => {
    props.onChange(!props.on);
  }
  
  return (
    <div className={"switch " + (props.on ? 'on' : '')} onClick={ () => doSwitch() }>
      <div className="circle"></div>
      <style jsx>{`
        .switch{ background: #DFDFE6; width: 28px; height: 18px; border-radius: 100px; position: relative; transition: .2s; cursor: pointer; }
          .switch.on{ background: #4E28DB; }
        .circle{ background: #fff; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); width: 14px; height: 14px; border-radius: 8px; position: absolute; top: 2px; left: 2px; transition: .2s; }
          .on .circle{ left: calc(100% - 16px); }
      `}</style>
    </div>
  )
}


export default Switch;
