import conf from "../conf.json";
import { ChromePicker } from 'react-color';
import { useEffect, useState, useContext } from "react";
import { GlobalContext } from '../context/GlobalContext';
import Switch from './Switch';

const ClientForm = (props) => {

    const [ client, setClient ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ errors, setErrors ] = useState(null);
    const [ pickerOn, setPickerOn ] = useState(null);
    const [ context, setContext ] = useContext(GlobalContext);

    useEffect(() => {
        if(context.authToken){
            if(props.id){
                fetch(conf.api + '/clients/' + props.id, {
                    method: 'GET',
                    headers: {
                        'admin-token': context.authToken
                    }
                }).then(res => {
                    if(res.ok){
                        return res.json();
                    }else{
                        setContext({...context, authToken: null});
                    }
                }).then(res => {
                    if(res){
                        setClient({...res, color: res.color ? res.color : '#cccccc' });
                    }
                });
            }else{
                setClient({
                    name: '',
                    email: '',
                    transaction: '',
                    password: '',
                    contact: '',
                    color: '',
                    active: true
                });
            }
        }
    }, [ context ]);

    const saveClient = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors(null);
        let url = conf.api + '/clients';
        if(props.id){
            url = url + '/' + client.id;
        }

        fetch(url, {
            method: props.id ? 'PUT' : 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'admin-token': context.authToken
            },
            body: JSON.stringify(client)
        }).then(res => res.json()).then(res => {
            setLoading(false);
            if(res.errors){
                setErrors(res.errors);
            }else{
                if(props.onDone){
                    props.onDone(res);
                }
            }
        });
    }

    return (
        <form onSubmit={(e) => saveClient(e)}>
            { client && 
                <div>
                    <div className="inner">
                        <div className="grid grid-2 grid-break narrow-h">
                            <div className={ errors && errors.name ? 'invalid' : ''}>
                                <label className="caps">Company</label>
                                <input type="text" className="field full mt-1" value={ client.name } onChange={(e) => setClient({...client, name: e.target.value })} />
                                { errors && errors.name && <div className="error-msg">{ errors.name[0] }</div> }
                            </div>
                            <div className={ errors && errors.email ? 'invalid' : ''}>
                                <label className="caps">Email</label>
                                <input type="email" className="field full mt-1" value={ client.email } onChange={(e) => setClient({...client, email: e.target.value })} />
                                { errors && errors.email && <div className="error-msg">{ errors.email[0] }</div> }
                            </div>
                            <div className={ errors && errors.transaction ? 'invalid' : ''}>
                                <label className="caps">Transaction Name</label>
                                <input type="text" className="field full mt-1" value={ client.transaction } onChange={(e) => setClient({...client, transaction: e.target.value })} />
                                { errors && errors.transaction && <div className="error-msg">{ errors.transaction[0] }</div> }
                            </div>
                            <div className={ errors && errors.contact ? 'invalid' : ''}>
                                <label className="caps">Contact</label>
                                <input type="text" className="field full mt-1" value={ client.contact } onChange={(e) => setClient({...client, contact: e.target.value })} />
                                { errors && errors.contact && <div className="error-msg">{ errors.contact[0] }</div> }
                            </div>
                            <div className={ errors && errors.password ? 'invalid' : ''}>
                                <label className="caps">Password</label>
                                <input type="text" className="field full mt-1" value={ client.password } onChange={(e) => setClient({...client, password: e.target.value })} />
                                { errors && errors.password && <div className="error-msg">{ errors.password[0] }</div> }
                            </div>
                            <div>
                                <label className="caps">Color</label>
                                <div className="picker-wp">
                                    <div className="color-input" style={{ backgroundColor: client.color }} onClick={() => setPickerOn(!pickerOn)}></div>
                                    { pickerOn &&
                                        <div>
                                            <div className="cover" onClick={ () => setPickerOn(false) }/>
                                            <div className="picker">
                                                <ChromePicker 
                                                    color={ client.color }
                                                    onChange={ (col) => setClient({...client, color: col.hex }) }
                                                    onChangeComplete={ (col) => setClient({...client, color: col.hex }) } 
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <div className="switch-wp">
                                    <Switch on={client.active} onChange={(on) => setClient({...client, active: on})} />
                                    <label>Active</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions">
                        <button className={"btn" + (loading ? ' loading' : '')}>
                            <div className="spinner-wp"><div className="spinner"></div></div>
                            <span>Save</span>
                        </button>
                    </div>
                </div>
            }
            <style jsx>{`
                .picker-wp{ position: relative; }
                .color-input{ width: 32px; height: 32px; border: #fff 2px solid; border-radius: 8px; margin-top: 8px; cursor: pointer; box-shadow: 0 0 0 1px #ddd; }
                .picker{ position: absolute; bottom: 0; left: 32px; z-index: 2; }
                .cover{ position: fixed; top: 0; left: 0; right: 0; bottom: 0; }
            `}</style>


        </form>
    )
}

export default ClientForm;