import conf from "../conf.json";
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faExternalLinkAlt, faFile } from '@fortawesome/free-solid-svg-icons'
import { ReactSortable } from "react-sortablejs";
import UploadButton from "./UploadButton";

const Attachment = ({attachment, onOpen}) => {
    return (
        <div>
            { attachment.type == 'link' &&
                <div className="manage-attachment">
                    <div className="icon" onClick={() => onOpen()}><FontAwesomeIcon icon={faLink} /></div>
                    <div className="name" onClick={() => onOpen()}>{attachment.name}</div>
                    <a href={attachment.path} className="external" target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                </div>
            }
            { attachment.type == 'file' &&
                <div className="manage-attachment">
                    <div className="icon" onClick={() => onOpen()}><FontAwesomeIcon icon={faFile} /></div>
                    <div className="name" onClick={() => onOpen()}>{attachment.name}</div>
                    <a href={conf.docsUrl + '/' + attachment.path.replace('public/', '')} className="external" target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                </div>
            }
        </div>
    )
}

const ManageAttachments = (props) => {

    const [ attachments, setAttachments ] = useState( props.card ? props.card.attachments : [] );
    const [ theLink, setTheLink ] = useState(null);
    const [ theFile, setTheFile ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ errors, setErrors ] = useState(null);

    useEffect(() => {
        props.onChange(attachments);
    }, [ attachments ])

    const saveLink = (e) => {
        e.preventDefault();
        let theErrors = {};
        setErrors(null);
        if(theLink.name == ''){
            theErrors.name = 'Please enter the label';
        }
        if(theLink.path == ''){
            theErrors.path = 'Please enter the URL';
        }
        if(Object.keys(theErrors).length === 0){
            let newAttachments = [];
            if(theLink.uid || theLink.id){
                newAttachments = attachments.map(item => {
                    if((item.id && item.id == theLink.id) || (item.uid && item.uid == theLink.uid)){
                        return theLink
                    }else{
                        return item
                    }
                });
            }else{
                newAttachments = [...attachments, {...theLink, uid: Date.now(), type: 'link'}];
            }
            setTheLink(null);
            setAttachments(newAttachments);
        }else{
            setErrors(errors);
        }
    }

    const saveFile = () => {
        let theErrors = {};
        setErrors(null);
        if(theFile.name == ''){
            theErrors.name = 'Please enter the file name';
        }
        if(Object.keys(theErrors).length === 0){
            let newAttachments = [];
            if(theFile.uid || theFile.id){
                newAttachments = attachments.map(item => {
                    if((item.id && item.id == theFile.id) || (item.uid && item.uid == theFile.uid)){
                        return theFile
                    }else{
                        return item
                    }
                });
            }
            setTheFile(null);
            setAttachments(newAttachments);
        }else{
            setErrors(errors);
        }
    }

    const openAttachment = (attachment) => {
        console.log(attachment);
        if(attachment.type == 'link'){
            setTheLink(attachment);
        }else if(attachment.type == 'file'){
            setTheFile(attachment);
        }
    }

    const deleteAttachment = (attachment) => {
        if(window.confirm('Are you sure ?')){
            let newAttachments = attachments.filter(item => {
                if((item.id && item.id == attachment.id) || (item.uid && item.uid == attachment.uid)){
                    return false
                }else{
                    return true
                }
            });
            setAttachments(newAttachments);
            setTheFile(null);
            setTheLink(null);
        }
    }

    return (
        <div>
            { attachments &&
                <ReactSortable list={attachments} setList={(updated) => setAttachments(updated)} onEnd={(e) => props.onChange(attachments)}>
                    { attachments.map(attachment => (
                        <Attachment key={attachment.id ? attachment.id : attachment.uid } attachment={attachment} onOpen={() => openAttachment(attachment) } />
                    ))}
                </ReactSortable>
            }
            <div className="grid grid-2 mt-2 narrow">
                <UploadButton onDone={(doc) => setAttachments([...attachments, { type: 'file', name: doc.name, path: doc.path, uid: Date.now() }])} />
                <span className="btn small outlined" onClick={() => setTheLink({ type: 'link', name: '', path: '' }) }><span>Add Link</span></span>
            </div>

            { theLink &&
                <div className="dialog">
                    <div className="box small">
                        <div className="heading">
                            <h3 className="h3">{ theLink.uid || theLink.id ? 'Edit' : 'Add'} Link</h3>
                            <span className="close" onClick={() => setTheLink(null)}>&times;</span>
                        </div>
                        <div className="inner">
                            <div className={ errors && errors.name ? 'invalid' : ''}>
                                <label className="caps">Label</label>
                                <input type="text" className="field full mt-1" value={ theLink.name } onChange={(e) => setTheLink({...theLink, name: e.target.value }) }/>
                                { errors && errors.name && <div className="error-msg">{ errors.name[0] }</div> }
                            </div>
                            <div className={"mt-3" + (errors && errors.path ? ' invalid' : '')}>
                                <label className="caps">URL</label>
                                <input type="url" className="field full mt-1" value={ theLink.path } onChange={(e) => setTheLink({...theLink, path: e.target.value }) }/>
                                { errors && errors.path && <div className="error-msg">{ errors.path[0] }</div> }
                            </div>
                        </div>
                        <div className="actions">
                            <button className={"btn" + (loading ? ' loading' : '')} onClick={(e) => saveLink(e) }>
                                <div className="spinner-wp"><div className="spinner"></div></div>
                                <span>Save</span>
                            </button>
                            { (theLink.uid || theLink.id) &&
                                <span className="btn o-red" onClick={(e) => deleteAttachment(theLink)}>
                                    <span>Delete</span>
                                </span>
                            }
                        </div>
                    </div>
                </div>
            }

            { theFile &&
                <div className="dialog">
                    <div className="box small">
                        <div className="heading">
                            <h3 className="h3">Uploaded File</h3>
                            <span className="close" onClick={() => setTheFile(null)}>&times;</span>
                        </div>
                        <div className="inner">
                            <div className={ errors && errors.name ? 'invalid' : ''}>
                                <label className="caps">File name</label>
                                <input type="text" className="field full mt-1" value={ theFile.name } onChange={(e) => setTheFile({...theFile, name: e.target.value }) }/>
                                { errors && errors.name && <div className="error-msg">{ errors.name[0] }</div> }
                            </div>
                            <div className={"mt-3" + (errors && errors.path ? ' invalid' : '')}>
                                <label className="caps">URL</label>
                                <div className="mt-1">
                                    <a className="link" target="_blank" href={ conf.docsUrl + '/' + theFile.path }>{ conf.docsUrl + '/' + theFile.path }</a>
                                </div>
                            </div>
                        </div>
                        <div className="actions">
                            <span className={"btn"} onClick={(e) => saveFile(e) }>
                                <span>Save</span>
                            </span>
                            <span className="btn o-red" onClick={(e) => deleteAttachment(theFile)}>
                                <span>Delete</span>
                            </span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ManageAttachments;