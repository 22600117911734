import { useState, useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import conf from "../conf.json";

const Percentage = ({val}) => (
    <div className="bar">
        <div className="fill" style={{ width: val + '%' }}></div>
    </div>
)

const Percentages = (props) => {

    const [ open, setOpen ] = useState(false);
    const [ client, setClient ] = useState(props.client);
    const [ loading, setLoading ] = useState(false);
    const [ context, setContext ] = useContext(GlobalContext);

    const savePercentages = (e) => {
        e.preventDefault();
        setLoading(true);
        fetch(conf.api + '/clients/' + props.client.id, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'admin-token': context.authToken
            },
            body: JSON.stringify(client)
        }).then(res => res.json()).then(res => {
            setLoading(false);
            if(props.onChange){
                props.onChange(res);
            }
            setOpen(false);
        });
    }

    return (
        <div>
            <div className={"percentages" + (!props.isClient ? ' editable' : '') } onClick={() => setOpen(true)}>
                <div className="width">
                    <div>
                        <div className="caps">Onboard</div>
                        <Percentage val={client.percentage_onboard} />
                    </div>
                    <div>
                        <div className="caps">Term Sheets</div>
                        <Percentage val={client.percentage_term_sheets} />
                    </div>
                    <div>
                        <div className="caps">Dilligence</div>
                        <Percentage val={client.percentage_dilligence} />
                    </div>
                    <div>
                        <div className="caps">Docs</div>
                        <Percentage val={client.percentage_docs} />
                    </div>
                    <div>
                        <div className="caps">Close</div>
                        <Percentage val={client.percentage_close} />
                    </div>
                </div>
            </div>
            { (open && !props.isClient) &&
                <form onSubmit={(e) => savePercentages(e)}>
                    <div className="dialog">
                        <div className="box">
                            <div className="heading">
                                <h3 className="h3">Update Progress</h3>
                                <span className="close" onClick={() => setOpen(false)}>&times;</span>
                            </div>
                            <div className="inner">
                                <div className="edit-percentages">
                                    <div className="item">
                                        <span>Onboard</span>
                                        <input type="number" className="field" value={client.percentage_onboard} onChange={(e) => setClient({...client, percentage_onboard: e.target.value })} />
                                        <span>%</span>
                                    </div>
                                    <div className="item">
                                        <span>Term Sheets</span>
                                        <input type="number" className="field" value={client.percentage_term_sheets} onChange={(e) => setClient({...client, percentage_term_sheets: e.target.value })} />
                                        <span>%</span>
                                    </div>
                                    <div className="item">
                                        <span>Dilligence</span>
                                        <input type="number" className="field" value={client.percentage_dilligence} onChange={(e) => setClient({...client, percentage_dilligence: e.target.value })} />
                                        <span>%</span>
                                    </div>
                                    <div className="item">
                                        <span>Docs</span>
                                        <input type="number" className="field" value={client.percentage_docs} onChange={(e) => setClient({...client, percentage_docs: e.target.value })} />
                                        <span>%</span>
                                    </div>
                                    <div className="item">
                                        <span>Close</span>
                                        <input type="number" className="field" value={client.percentage_close} onChange={(e) => setClient({...client, percentage_close: e.target.value })} />
                                        <span>%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="actions">
                                <button className={"btn" + (loading ? ' loading' : '')}>
                                    <span className="spinner-wp"><span className="spinner"></span></span>
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </div>
    )
}

export default Percentages;