import conf from "../conf.json";
import { useEffect, useContext } from "react";
import ClientForm from '../components/ClientForm';
import { GlobalContext } from '../context/GlobalContext';

const EditClient = (props) => {

    const [ context, setContext ] = useContext(GlobalContext);

    const id = props.match.params.id;

    useEffect(() => {
        setContext({...context, activeTab: 'edit-client'});
    }, []);

    
    return (
        <div>
            { !context.isClient &&
                <div className="width pt-7">
                    <div className="box large">
                        <div className="heading">
                            <h2 className="h2">Edit Client</h2>
                        </div>
                        <ClientForm id={id} onDone={(updated) => {
                            if(props.onChanged){
                                props.onChanged(updated);
                            }
                        }} />
                    </div>
                </div>
            }
        </div>
    );
}

export default EditClient;