import { Link } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import { useContext } from 'react';

const Header = () => {

    const [ context, setContext ] = useContext(GlobalContext);

    const logout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('isClient');
        localStorage.removeItem('clientName');
        localStorage.removeItem('transaction');
        setContext({...context, authToken: null, isClient: false, clientName: null, transaction: null });
    }

    return (
        <header className="header">
            <div className="width">
                <Link to={'/'}>
                    <a href="" className="logo">
                        <img src="/img/BetterSeriesALogo.png" alt="Better Series A" />
                        { context.authToken ? (
                            <div>
                                { context.isClient ? (
                                    <div className="client-title">
                                        <div className="name">{context.clientName}</div>
                                        <div className="transaction">{context.transaction} Status Bar</div>
                                    </div>
                                ) : (
                                    <span>Admin</span>
                                )}
                            </div>
                        ) : (<span>Status Bar</span>)}
                    </a>
                </Link>
                
                { context.authToken &&
                    <div className="right">
                        { !context.isClient &&
                            <Link to={'/change-password'}><a className="action">Change Password</a></Link>
                        }
                        <span className="action" onClick={() => logout()}>Logout</span>
                    </div>
                }
                
            </div>
        </header>
    )
}

export default Header;